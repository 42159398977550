<template>
  <div>
    <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
      <div class="post d-flex flex-column-fluid">
        <div id="kt_content_container" class="container-xxl">
          <div class="d-flex bg-white rounded">
            <div class="py-5 px-9 w-100">
              <div
                  class="d-flex w-100 bg-white rounded justify-content-between align-items-center"
              >
                <div>
                  <h3>Create Book</h3>
                </div>
                <div class="d-flex justify-content-end">
                  <div class="mr-2">
                    <button
                        class="btn btn-sm btn-light-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#modal_new_coupon"
                        @click="resetBook"
                    >
                      <span class="svg-icon svg-icon-3">
                        <inline-svg
                            src="media/icons/duotone/Interface/Plus-Square.svg"
                        />
                      </span>
                      New Book
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex bg-white rounded">
            <div class="pb-5 px-9 w-100">
              <div class="form-group">
                <label class="form-label">Book Title</label>
                <input
                    v-model="bookTitle"
                    type="text"
                    class="form-control form-control-solid"
                    placeholder="Book Title"
                />
              </div>
            </div>
          </div>
          <div
              class="stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid mt-7"
              id="kt_create_account_stepper"
          >
            <div
                class="d-flex justify-content-center bg-white rounded justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9 stepper-scroll h-600px"
            >
              <div class="px-6 px-lg-10 px-xxl-15 py-20">
                <div class="stepper-nav">
                  <div
                      class="stepper-item"
                      :class="{ active: currentIndex == index }"
                      data-kt-stepper-element="nav"
                      v-for="(page, index) in pages"
                      :key="index"
                      @click="getPage(index)"
                  >
                    <div class="stepper-line w-40px"></div>
                    <div class="stepper-icon w-40px h-40px">
                      <i class="stepper-check fas fa-check"></i>
                      <span class="stepper-number">{{ index + 1 }}</span>
                    </div>
                    <div class="stepper-label">
                      <h3 class="stepper-title">{{ `Page ${index + 1}` }}</h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row-fluid bg-white rounded w-100">
              <div
                  class="py-15 w-100 w-xl-700px px-9"
                  id="kt_create_account_form"
              >
                <div class="current" data-kt-stepper-element="content">
                  <div class="w-100">
                    <h3 class="pb-5">{{ `Page ${currentIndex + 1}` }}</h3>
                    <div class="pb-10 pb-lg-15">
                      <el-form
                          id="kt_modal_new_target_form"
                          :model="selectedPage"
                          :rules="rules"
                          ref="formRef"
                          class="form"
                      >
                        <div class="mb-5">
                          <el-form-item prop="title">
                            <label class="form-label">Title</label>
                            <input
                                v-model="selectedPage.title"
                                type="text"
                                class="form-control form-control-solid"
                                placeholder="Page Title"
                            />
                          </el-form-item>
                        </div>

                        <div class="mb-5">
                          <el-form-item prop="description">
                            <label class="form-label">Description</label>
                            <editor
                                initialValue="<p>Initial editor content</p>"
                                v-model="selectedPage.description"
                                :init="{
                                  height: 500,
                                  menubar: false,
                                  plugins: [
                                    'advlist autolink lists link image charmap',
                                    'searchreplace visualblocks code fullscreen',
                                    'print preview anchor insertdatetime media',
                                    'paste code help wordcount table',
                                  ],
                                  toolbar:
                                    'undo redo | formatselect | bold italic |         alignleft aligncenter alignright | lineheight |         bullist numlist outdent indent | help',
                                  line_height_formats: '1 1.5 2 2.5 3 3.5 4',

                                }"
                            >
                            </editor>
                          </el-form-item>
                        </div>
                      </el-form>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-end">
                  <div class="mr-2">
                    <el-popconfirm title="Are you sure to delete this page?"
                                   @confirm="deleteCurrentPage">
                      <template #reference>
                        <el-button class="btn btn-light-danger me-3" :disabled="this.pages.length === 1">Delete current
                          page
                        </el-button>
                      </template>
                    </el-popconfirm>
                  </div>
                  <div class="mr-2">
                    <button
                        type="button"
                        class="btn btn-light-success me-3"
                        @click="makeDocument"
                    >
                      Save
                      <span class="svg-icon svg-icon-2">
                        <inline-svg
                            src="media/icons/duotune/arrows/arr078.svg"
                        />
                      </span>
                    </button>
                  </div>
                  <div class="mr-2">
                    <button
                        type="button"
                        class="btn btn-light-primary me-3"
                        @click="handlePageChange"
                    >
                      Next
                      <span class="svg-icon svg-icon-4 ms-1 me-0"
                      ><svg
                          fill="none"
                          viewBox="0 0 24 24"
                          height="24"
                          width="24"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                          <rect
                              xmlns="http://www.w3.org/2000/svg"
                              opacity="0.5"
                              x="18"
                              y="13"
                              width="13"
                              height="2"
                              rx="1"
                              transform="rotate(-180 18 13)"
                              fill="currentColor"
                          ></rect>
                          <path
                              xmlns="http://www.w3.org/2000/svg"
                              d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                              fill="currentColor"
                          ></path></svg
                      ></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import Editor from "@tinymce/tinymce-vue";
import ApiService from "../core/services/ApiService";

export default {
  name: "Book",
  components: {
    editor: Editor,
  },
  data() {
    return {
      currentIndex: 0,
      pages: [
        {
          name: "Page #1",
          title: "",
          description: "",
        },
      ],
      htmlBookId: null,
      bookTitle: "",
      selectedPage: {
        title: "",
        description: "",
      },
      rules: {
        title: [
          {
            required: true,
            message: "Please input Title",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Please input description",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    "selectedPage.title"(val) {
      this.pages[this.currentIndex].title = val;
    },
    "selectedPage.description"(val) {
      this.pages[this.currentIndex].description = val;
    },
  },
  methods: {
    handlePageChange() {
      this.$refs["formRef"].validate((valid) => {
        if (valid) {
          this.nextPage();
        } else {
          return false;
        }
      });
    },
    nextPage() {
      if (this.currentIndex == this.pages.length - 1) {
        const index = this.pages.length + 1;
        let newPage = {
          name: `Page #${index}`,
          title: "",
          description: "",
        };

        this.pages.push(newPage);
        this.selectedPage = {...this.pages[this.pages.length - 1]};
        this.currentIndex++;
        this.selectedPage = {
          title: "",
          description: "",
        };
      } else {
        this.getPage(this.currentIndex + 1);
      }
    },
    getPage(index) {
      this.currentIndex = index;
      this.selectedPage = this.pages[index];
    },
    deleteCurrentPage() {
      this.pages.splice(this.currentIndex, 1);
      if (this.currentIndex > 0)
        this.currentIndex--;
      this.getPage(this.currentIndex)
    },
    makeDocument() {
      if (this.htmlBookId) {
        ApiService.put(`admin/html-book/${this.htmlBookId}`, {
          title: this.bookTitle,
          pages: this.pages
        })
      } else {
        ApiService.post('admin/html-book', {
          title: this.bookTitle,
          pages: this.pages
        })
      }
      let doc = document.implementation.createHTMLDocument("New Document");
      this.pages.forEach((page, index) => {
        let section = doc.createElement("section");
        section.setAttribute("id", `chapter-${index + 1}`);
        let h3 = doc.createElement("h3");
        h3.setAttribute('dir', 'rtl')
        h3.textContent = page.title;
        let div = doc.createElement("div");
        div.innerHTML = page.description;
        div.setAttribute('dir', 'rtl')
        console.log(div);
        section.appendChild(h3);
        section.appendChild(div);
        try {
          doc.body.appendChild(section);
        } catch (e) {
          console.log(e);
        }
      });
      let blob = new Blob([`<html>${doc.body.outerHTML}</html>`], {
        type: "text/html",
      });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download =
          "new-book" + "-" + moment().format("YYYY-MM-DD-HHmm") + ".html";
      link.click();
    },
    resetBook() {
      this.pages = [
        {
          name: "Page #1",
          title: "",
          description: "",
        },
      ];
      this.selectedPage = {...this.pages[0]};
      this.currentIndex = 0;
    },
  },
  mounted() {
    this.htmlBookId = this.$route.params.id;
    if (this.htmlBookId) {
      ApiService.get(`admin/html-book/${this.htmlBookId}`)
          .then(({data}) => {
            this.pages = data.pages;
            this.bookTitle = data.title
            if (this.pages && this.pages.length)
              this.getPage(0);
          })
          .catch(() => {
            this.$router.push({name: 'html-books'});
          });
    }
    this.selectedPage = {...this.pages[0]};
  },
};
</script>
<style scoped>
.stepper .stepper-scroll {
  position: relative;
  overflow: auto;
}

.stepper-item {
  cursor: pointer;
}

.stepper-item.active .stepper-title {
  color: #009ef7 !important;
}

.stepper-item.active .stepper-icon {
  transition: color 0.2s ease, background-color 0.2s ease;
  background-color: #009ef7;
}

.stepper-item.active .stepper-icon .stepper-number {
  color: #fff !important;
}
</style>
